import { Col, Row } from 'antd';
import React, { FC, useEffect, useState } from 'react';
import ItemsCarousel from 'react-items-carousel';
import { ChallengeTypeEnum } from 'src/shared/enums';
import { ChallengeDetail } from 'src/shared/models';
import { renderChallengeCard } from '../../../utils';

const MIN_NUMBER_OF_CAROUSEL_CARD = 1;

interface OwnProps {
  challenges: ChallengeDetail[];
  name: string;
}

const ChallengeCardCarousel: FC<OwnProps> = ({
  challenges,
  name,
}): JSX.Element => {
  if (challenges.length <= 0) return <React.Fragment />;
  const isCampaign =
    challenges.filter(
      ({ challengeType }) => challengeType === ChallengeTypeEnum.CAMPAIGN
    ).length > 0;
  const isTraining =
    challenges.filter(
      ({ challengeType }) => challengeType === ChallengeTypeEnum.FORMATION
    ).length > 0;
  const [activeItemIndex, setActiveItemIndex] = useState(1);
  const [carouselGutter, setCarouselGutter] = React.useState(-100);
  const [carouselCards, setCarouselCards] = React.useState(1);
  const [hideCarousel] = React.useState(
    challenges.length <= MIN_NUMBER_OF_CAROUSEL_CARD || isCampaign
  );
  const renderMaterialsCard = ({
    cardSpan,
    carouseName,
  }: {
    cardSpan: object;
    carouseName: string;
  }) => {
    return challenges.map((challenge: ChallengeDetail) => {
      return (
        <Col
          key={`${carouseName}-challengeCard--${challenge.challengeId}`}
          {...{ ...cardSpan }}
          className="material-card"
        >
          {renderChallengeCard({
            challenge,
            isCarousel: true,
          })}
        </Col>
      );
    });
  };
  useEffect(() => {
    const handleResize = () => {
      let _gutter: number;
      let _cards = 1;
      switch (true) {
        case window.innerWidth >= 1100:
          _gutter = isCampaign ? -260 : -40;
          _cards = isCampaign ? 2 : 3;
          break;
        case window.innerWidth >= 1000:
          _gutter = isCampaign ? -160 : -40;
          _cards = isCampaign ? 2 : 3;
          break;
        case window.innerWidth >= 930:
          _gutter = isCampaign ? -90 : -150;
          _cards = 2;
          break;
        case window.innerWidth >= 800 && isCampaign:
          _gutter = -330;
          _cards = 1;
          break;
        case window.innerWidth >= 768:
          _gutter = isCampaign ? -270 : -90;
          _cards = isCampaign ? 1 : 2;
          break;
        case window.innerWidth >= 600 && isCampaign:
          _gutter = -330;
          _cards = 1;
          break;
        case window.innerWidth >= 676:
          _gutter = isCampaign ? -130 : -(window.innerWidth / 2);
          _cards = 1;
          break;
        case window.innerWidth >= 576:
          _gutter = isCampaign ? -130 : -(window.innerWidth / 3);
          _cards = 1;
          break;
        case window.innerWidth >= 526:
          _gutter = isCampaign ? -130 : -200;
          _cards = 1;
          break;
        case window.innerWidth >= 440:
          _gutter = -130;
          break;
        case window.innerWidth >= 400:
          _gutter = isCampaign ? -50 : -65;
          break;
        case window.innerWidth >= 370:
          _gutter = isCampaign ? -40 : -65;
          break;
        case window.innerWidth >= 350:
          _gutter = -70;
          break;
        case window.innerWidth >= 320:
          _gutter = isCampaign ? -10 : -60;
          break;
      }
      setCarouselGutter(_gutter);
      setCarouselCards(_cards);
    };
    window.addEventListener('resize', handleResize);
    handleResize();
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  });
  return (
    <>
      {/* DESKTOP MATERIAL*/}
      <Row
        className={`ChallengeCardCarousel ChallengeCardCarousel__container-desktop ${
          isCampaign || isTraining
            ? 'campaign'
            : hideCarousel
            ? 'show_only_desktop'
            : 'normal'
        }`}
        justify={challenges.length == 2 ? 'center' : 'space-around'}
        gutter={hideCarousel ? [0, 0] : [24, 24]}
      >
        {renderMaterialsCard({
          cardSpan:
            isCampaign || isTraining ? { xs: 24, sm: 24, xl: 8 } : { span: 8 },
          carouseName: `${name}-desktop`,
        })}
      </Row>
      {/* CAROUSEL*/}
      <div
        className={`ChallengeCardCarousel__container_carousel ${
          hideCarousel ? 'no-display' : 'normal'
        }`}
      >
        <ItemsCarousel
          requestToChangeActive={setActiveItemIndex}
          activeItemIndex={activeItemIndex}
          numberOfCards={carouselCards}
          gutter={carouselGutter}
        >
          {renderMaterialsCard({
            cardSpan: { span: 24 },
            carouseName: `${name}-carousel`,
          })}
        </ItemsCarousel>
      </div>
    </>
  );
};
export default ChallengeCardCarousel;
