import { Row } from 'antd';
import React from 'react';
import { getComponentHeader } from '.';
import { PageDetailEnum } from '../../shared/enums';
import { IContentComponent, IContentDetail } from '../../shared/models';
import { getTranslatedData } from '../../../utils';

const { IMAGE, TEXT } = PageDetailEnum;

interface Props {
  component: IContentComponent;
}

export const Banner = ({ component }: Props) => {
  const { componentDetails, icon, title } = component;

  let image: IContentDetail, text: IContentDetail;

  componentDetails
    .filter((detail) => detail.status)
    .forEach((detail) => {
      if (detail.type === IMAGE) image = detail;
      if (detail.type === TEXT) text = detail;
    });

  if (!image) return <></>;

  return (
    <Row justify="center" align="middle" className="banner">
      {title && (
        <Row
          justify="center"
          align="middle"
          className="container--no-padding banner__title"
        >
          {getComponentHeader({
            icon,
            title: getTranslatedData(component, 'content') || title,
          })}
        </Row>
      )}
      {text && text.value && (
        <Row
          justify="center"
          align="middle"
          className="container block banner__text"
        >
          <Row justify="center" className="regular-body">
            {getTranslatedData(text, 'content') || text.value}
          </Row>
        </Row>
      )}

      <img
        loading="lazy"
        className="cardImageFull__imageMobile"
        alt={text?.value}
        src={image.valueMobile}
      />
      <img
        loading="lazy"
        className="cardImageFull__imageDesktop"
        alt={text?.value}
        src={image.value}
      />
    </Row>
  );
};
