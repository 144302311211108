import React from 'react';
import { IContentComponent, IContentDetail } from 'src/shared/models';
import { PageDetailEnum, PageDetailLinkedImage } from 'src/shared/enums';
import { Card, Col, Row } from 'antd';
import { getComponentHeader } from '.';
import Link from 'src/components/Link';
import { getTranslatedData } from '../../../utils';
import { FormattedMessage } from 'react-intl';

const { IMAGE, TEXT } = PageDetailEnum;
const { FULL, RIGHT, LEFT } = PageDetailLinkedImage;

interface Props {
  component: IContentComponent;
  imageType?: 'long' | 'short';
}

const cardDescription = (description: string, route: string): JSX.Element => (
  <>
    {description && <p className="regular-body">{description}</p>}
    {route && (
      <>
        <Link href={route}>
          <Row justify="center" align="middle">
            <a className="static-header-title cardImage__link cardImage__link--active">
              <FormattedMessage id="training.view-all" />
            </a>
            <a className="icon icon--arrow-dropdown-right" />
          </Row>
        </Link>
      </>
    )}
  </>
);

const renderCard = ({
  description,
  icon,
  imagePosition,
  imageValue,
  imageMobileValue,
  route,
  title,
}: {
  description?: string;
  icon?: number;
  imagePosition: number;
  imageValue: string;
  imageMobileValue: string;
  route?: string;
  title?: string;
}) => {
  const imageClassName =
    imagePosition === RIGHT ? 'cardImageBefore' : 'cardImageAfter';

  const cardHeader = getComponentHeader({ icon, title });

  return (
    <div
      className="container block cardImage linked-image"
      style={{ padding: 0 }}
    >
      <Row justify="center" align="middle">
        <Col className={imageClassName}>
          <Row justify="center" align="middle" className="block--small">
            <Card
              bordered={false}
              className={imageClassName + '__card'}
              cover={
                <>
                  <img
                    loading="lazy"
                    className={`${imageClassName}__image not_show_on_tablet`}
                    alt={description}
                    src={imageValue}
                  />
                  <img
                    loading="lazy"
                    className={`${imageClassName}__image show_on_tablet`}
                    alt={description}
                    src={imageMobileValue}
                  />
                </>
              }
            >
              <Card.Meta
                className={imageClassName + '__meta regular-body'}
                title={cardHeader}
                description={cardDescription(description, route)}
              />
            </Card>
          </Row>
        </Col>
      </Row>
    </div>
  );
};

const renderFullImage = ({
  description,
  icon,
  imageValue,
  imageMobileValue,
  route,
  title,
}: {
  description?: string;
  icon?: number;
  imageValue: string;
  imageMobileValue: string;
  route: string;
  title?: string;
}) => {
  return (
    <div className="cardImage block linked-image">
      <Row className="cardImageFull" justify="center" align="middle">
        <Row
          justify="end"
          align="middle"
          className="cardImageFull__container block"
        >
          <img
            loading="lazy"
            className="cardImageFull__imageMobile"
            alt={description}
            src={imageMobileValue}
          />
          <img
            loading="lazy"
            className="cardImageFull__imageDesktop"
            alt={description}
            src={imageValue}
          />
          <Card bordered={false} className="cardImageFull__card">
            <Card.Meta
              className={'cardImageFull__meta regular-body'}
              title={getComponentHeader({ icon, title })}
              description={cardDescription(description, route)}
            />
          </Card>
        </Row>
      </Row>
    </div>
  );
};

export const LinkedImage = ({ component }: Props) => {
  const { componentDetails, icon, title } = component;
  let image: IContentDetail, text: IContentDetail;

  componentDetails
    .filter((detail) => detail.status)
    .forEach((detail) => {
      if (detail.type === IMAGE) image = detail;
      if (detail.type === TEXT) text = detail;
    });

  if (!image) return <></>;

  const { position, slug, value, valueMobile } = image;

  switch (position) {
    case FULL:
      return renderFullImage({
        description: text?.value,
        icon,
        imageMobileValue: valueMobile,
        imageValue: value,
        route: slug,
        title,
      });
    case LEFT:
    case RIGHT:
      return renderCard({
        description: getTranslatedData(text, 'content') || text?.value,
        icon,
        imagePosition: position,
        imageMobileValue: valueMobile,
        imageValue: value,
        route: slug,
        title: getTranslatedData(component, 'content') || title,
      });
    default:
      return;
  }
};
