import { Row } from 'antd';
import React from 'react';
import { getComponentHeader } from '.';
import { IContentComponent } from '../../shared/models';
import { getTranslatedData } from '../../../utils';
import { DangerouslySetHtmlContent } from '../common/DangerouslySetHtmlContent';

interface Props {
  component: IContentComponent;
}

export const WYSComponent = ({ component }: Props) => {
  const { componentDetails, icon, title } = component;

  return (
    <>
      {title && (
        <Row
          justify="center"
          align="middle"
          className="container--no-padding block"
        >
          {getComponentHeader({
            icon,
            title: getTranslatedData(component, 'content') || title,
          })}
        </Row>
      )}
      {componentDetails
        .filter((detail) => detail.status)
        .map((detail, index) => (
          <div key={`wyswyg-${index}`} style={{ width: '100vw' }}>
            <DangerouslySetHtmlContent html={detail.value || <p></p>} />
          </div>
        ))}
    </>
  );
};
